import React from 'react';
import {  Result } from 'antd';


const LinkExpiredPage: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Result
        status="warning"
        title="Link Expired"
        subTitle="The link you followed has expired. Please request a new one to continue."
      />
    </div>
  );
};

export default LinkExpiredPage;
