import { store } from "../redux/authStore";
import { orgtokenVerify } from "./tokenVerify";

export const authHeader = () => {
  if (orgtokenVerify()) {
    const token = sessionStorage.getItem("current_tab_application_token") || store.getState().opening.loginUser.token;
    if (token) {
      return {
        Accept: "applizcation/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }  
     window.location.replace(`${process.env.REACT_APP_WEB_URL}`);
  } else {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}link-expire`);
  }
};

export const authHeader1 = () => {
  if (orgtokenVerify()) {
    const token = localStorage.getItem("login_token");
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
  }
};

export const atsUploadHeader = () => {
  if (orgtokenVerify()) {
    const token = sessionStorage.getItem("current_tab_application_token");
    return {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
  }
};
export const uploadHeader = () => {
  if (orgtokenVerify()) {
    const token = localStorage.getItem("login_token");
    return {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
  }
};

// billing
export const billingHeader = () => {
  if (orgtokenVerify()) {
    return {
      //there will be an API key fethced from Backend which gonna be here as Authorization :`Bearer ${API_KEY}`
      Authorization: `Bearer 42cedea1-b3d3-4eb1-8810-fe9248dadbff`,
    };
  } else {
    window.location.replace(`${process.env.REACT_APP_WEB_URL}/login`);
  }
};
