import React, { Component } from "react";

// import NavigationSlider from "../../components/sidePanal/navigationSlider/NavigationSlider";
// import SideNavbar from "../../components/navbar/SideNavbar";
// import SlackIntegration from "../../components/subNavbar/slack/SlackIntegration";
import { SettingsRoute, TACITBASE, TrackerRoute } from "../constant";

import { FormBuilder, Jd, List, loginUser, notificationServiceEntityDotChangePayloadType, notificationServiceEntityDotRemovePayloadType, Opening, OpeningMembers, organisationState } from "../type/type";
import BoardPanel from "../components/boardPanel/BoardPanel";
import { getBoardByid, getBoardLabel, getJDbyBoardId, getOpenings } from "../lib/api";
import { Calendar, WatcherSubscription, allNotifications } from "../lib/apiRes";
import { SUBSCRIBE } from "../redux/actionType";
import { connect } from "react-redux";

import { ConfigProvider, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Dispatch } from "redux";
import { addWsRequest, calenderId, changeServiceNotificationsCountValue, clearBoardActivity, initBoardLabels, initCurrentBoard, initCurrentBoardWatcher, initCurrentTrackerForm, initCurrentTrackerJd, initEntityUnreadNotificationsBoard, initLists, initOpeningMembership, initServiceEntityNotificationsDot, listOfBoards, removeCurrentBoard, setCurrentCal, slackOptions, topicSubscription, unread_dot_remover, updateSearchFlag } from "../redux/actions/opening/action";
// import Filter from "../components/subNavbar/filter/Filter";
import JoinPublicBoard from "../components/subNavbar/addBoardMember/JoinPublicBoard";
import SubNavbar from "../components/subNavbar/SubNavbar";
import SettingsPanel from "../components/boardPanel/createList/jobPost/tabs/settings/SettingsPanel";
import Filter from "../components/subNavbar/filter/Filter";
type props = {
  orgStatus: organisationState;
  currentOpening: Opening;
  initCardNotificationDot(
    payload: notificationServiceEntityDotChangePayloadType
  ): void;
  boardLabels(boardData: any, id: string): void;
  topicSubscription(topic: any): void;
  removeCurrentBoard(): void;
  saveCurrentBoard(board: Opening): void;
  initEntityUnreadNotificationsBoard(notification: allNotifications[]): void;
  initCurrentBoardWatcher(data: WatcherSubscription): void;
  clearBoardActivity(): void;
  removeBoardNotificationDot(
    payload: notificationServiceEntityDotRemovePayloadType
  ): void;
  initCurrenTrackerJd(payload: Jd): void;
  initCurrentTrackerJdForm(payload: FormBuilder): void;

  // initBoardCardNotifications(notification: Notification[]): void;
  changeServiceNotificationsCount(payload: {}): void;
  topic: string;
  cal_id: string;
  match: any;
  applicationId: string;
  updateSearchFlag(): void;
  setSlackOptions(option: any): void;
  initOpeningMembers(member: any, openingId: string): void;
  addWsRequest(actionRequest: any): void;
  setCalenderId(cal_id: string): void;
  setCurrentCalender(data: Calendar): void;
  // initMembers(members: any): void;
  history: any;
  openingMembers: OpeningMembers;
  loginUser: loginUser;
  initLists(list: List[]): void;
  notificationCenter: any;
  listOfBoards(boardData: any): void,
};
type state = {
  sidebarOpen: boolean;
  showPopup: boolean;
  showCal: boolean;
  activeTab: string;
};

class Board extends Component<props, state> {

  state = {
    sidebarOpen: false,
    showPopup: false,
    showCal: false,
    activeTab: "",
  };

  handleClick = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };
  openSideBar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };
  showPopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  toggleViewBetweenCalAndList = () => {
    this.setState({
      showCal: !this.state.showCal,
    });
  };
  componentDidMount() {
    this.loadBoardData()

    // API call
  }
  componentDidUpdate(prevProps: any) {

    const prevBoardId = prevProps.match.params.board_id
    const currentBoardId = this.props.match.params.board_id

    if (prevBoardId !== currentBoardId) {
      this.props.removeCurrentBoard()
      this.loadBoardData()
    }
  }
  loadBoardData() {
    let shouldCallApis = this.props.history.location.method === "post" ? false : true;


    const { match, saveCurrentBoard, loginUser, applicationId, addWsRequest, initCurrenTrackerJd, initCurrentTrackerJdForm, setCalenderId, setCurrentCalender, boardLabels, currentOpening } = this.props
    const { tabname, board_id } = match.params

    if (tabname) {
      this.setState({ activeTab: tabname })
    } else {
      this.setState({ activeTab: TrackerRoute })
    }

    if ((sessionStorage.getItem("current_tab_application_token") || this.props.loginUser.token) && shouldCallApis) {
      getBoardByid(board_id).then((opening: Opening) => {
        document.title = `${opening.name} | ${TACITBASE}`
        saveCurrentBoard(opening)

        addWsRequest({
          type: SUBSCRIBE,
          model_type: "board",
          model_id: board_id,
          user_id: loginUser.user.id,
          org_id: applicationId,
          time: Math.floor(new Date().getTime() / 1000),
        })


      })


      getBoardLabel(board_id).then((labels: any) => {
        boardLabels(labels, currentOpening.id)
      })
      getOpenings().then((openings) => {
        this.props.listOfBoards(openings)
      })
    }
    getJDbyBoardId(board_id)
      .then((res) => {
        initCurrenTrackerJd(res)

      })
      .catch((err) => {
        console.error("error", err)
      })
  }
  componentWillUnmount() {
    this.props.removeCurrentBoard();
  }
  handleTabChange = (key: string) => {
    this.setState({ ...this.state, activeTab: key });
    // Update URL when tab is changed
    const { org_id } = this.props.match.params;

    this.props.history.push(
      `/o/${org_id}/b/${this.props.currentOpening.id}/${key}`
    );
  };
  render() {
    // Define tabs as a separate variable
    const tabs = [

      {
        key: "tracker",
        label: "Tracker",
        path: `/${TrackerRoute}`,

        content: <BoardPanel />,
      },
      {
        key: "settings",
        label: "Settings",
        path: `/${SettingsRoute}`,

        content: (
          <SettingsPanel />),
      },
      // Add more tabs as needed
    ];


    return (

      <React.Fragment>
        {/* <Read> */}
        <div className="flex flex-col h-full ">
          <div
            style={{ height: 80 }}
            className="px-4 w-full  justify-center items-center bg-black  bg-opacity-50   text-sm cursor-pointer focus:outline-none"
          >
            {" "}
            {/* Adjust width as needed */}
            <SubNavbar
              showPopup={this.showPopup}
              click={this.handleClick}
              sliderState={this.state.sidebarOpen}
              showCal={this.toggleViewBetweenCalAndList}
              toggleValue={this.state.showCal}
            />{" "}
            <div className="flex flex-row items-center justify-between">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      cardPadding: "0px",
                      itemColor: "rgba(255,255,255, 0.7)",
                      itemSelectedColor: "rgba(255,255,255, 1)",
                      itemActiveColor: "rgba(255,255,255, 1)", inkBarColor: "white", horizontalItemPadding: "0px 0px 8px 0px", itemHoverColor: "rgba(255,255,255, 0.9)"
                    },
                  },
                }}
              >
                <Tabs
                  defaultActiveKey={this.state.activeTab}
                  onChange={this.handleTabChange}
                  size="small"
                  className="text-white  " activeKey={this.state.activeTab}
                >
                  {tabs.map((tab) => (
                    <TabPane
                      forceRender
                      className="p-0 "
                      key={tab.key}
                      tab={tab.label}
                    ></TabPane>
                  ))}
                </Tabs>
              </ConfigProvider>
              {this.state.activeTab === "tracker" && (
                <div className="ml-2">
                  <Filter />
                </div>
              )}
            </div>
          </div>
          <div className="w-full h-full overflow-y-hidden ">
            {" "}
            {/* Adjust width as needed */}
            {tabs.map((tab) => (
              <div
                key={tab.key} className={`${this.state.activeTab === tab.key ? 'h-full' : 'hidden'}`}

              >

                {this.state.activeTab === tab.key && (<>{tab.content}</>)}

                {/* {tab.content} */}
              </div>
            ))}
          </div>
        </div>

        {/* <SideNavbar isBgColorExist={true} /> */}
        {/* </Read> */}

        {this.props.openingMembers[this.props.currentOpening.id] ? (
          this.props.openingMembers[this.props.currentOpening.id].some(
            (member) => member.id === this.props.loginUser.user.id
          ) || this.props.currentOpening.access.admin ? null : !this.props
            .currentOpening.member_only ? (
            <div className="animate-popover_fadeIn rounded justify-center inset-0 bg-black bg-opacity-50 items-start pt-14 flex fixed  outline-none focus:outline-none">
              <JoinPublicBoard />
            </div>
          ) : null
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
  topic: state.opening.topicSubscription,
  applicationId: state.opening.applicationId,
  cal_id: state.opening.calendar.currentCalenderId,
  orgStatus: state.opening.orgStatus,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  saveCurrentBoard: (board: Opening) => dispatch(initCurrentBoard(board)),
  initLists: (list: List[]) => dispatch(initLists(list)),
  updateSearchFlag: () => dispatch(updateSearchFlag()),
  boardLabels: (boardData: any, id: string) =>
    dispatch(initBoardLabels(boardData, id)),
  topicSubscription: (topic: any) => dispatch(topicSubscription(topic)),
  removeCurrentBoard: () => dispatch(removeCurrentBoard()),
  setSlackOptions: (options: any) => dispatch(slackOptions(options)),

  initEntityUnreadNotificationsBoard: (notification: allNotifications[]) =>
    dispatch(initEntityUnreadNotificationsBoard(notification)),
  initOpeningMembers: (members: any, openingId: string) => {
    dispatch(initOpeningMembership(members, openingId));
  },
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
  setCalenderId: (cal_id: string) => dispatch(calenderId(cal_id)),
  setCurrentCalender: (data: Calendar) => dispatch(setCurrentCal(data)),
  initCurrentBoardWatcher: (data: WatcherSubscription) =>
    dispatch(initCurrentBoardWatcher(data)),
  changeServiceNotificationsCount: (payload: {}) =>
    dispatch(changeServiceNotificationsCountValue(payload)),
  initCardNotificationDot: (
    payload: notificationServiceEntityDotChangePayloadType
  ) => dispatch(initServiceEntityNotificationsDot(payload)),
  clearBoardActivity: () => dispatch(clearBoardActivity()),
  removeBoardNotificationDot: (
    payload: notificationServiceEntityDotRemovePayloadType
  ) => dispatch(unread_dot_remover(payload)),
  initCurrenTrackerJd: (payload: Jd) => dispatch(initCurrentTrackerJd(payload)),
  initCurrentTrackerJdForm: (payload: FormBuilder) =>
    dispatch(initCurrentTrackerForm(payload)),
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
});
export default connect(mapStateToProps, mapPropsTostate)(Board);
